// 员工管理
<template>
  <div class="staff">
    <div class="staff_box b-c-f">
      <div class="staff_head p-t-30">
        <el-form
          ref="form"
          class="staff_head_form f-1 flexs flex-wrap"
          :model="form"
          label-width="80px"
        >
          <el-form-item label="时间:">
            <el-date-picker
              v-model="form.position_time"
              type="date"
              @change="changeTime"
              placeholder="请选择"
            >
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="区域:">
            <el-input v-model="form.name" placeholder="请选择"></el-input>
          </el-form-item> -->
          <el-form-item label="门店:">
            <el-select
              @change="changeArea"
              clearable
              v-model="form.dian_id"
              placeholder="请选择"
            >
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.dian_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职位:">
            <el-select
              clearable
              @change="changePost"
              v-model="form.position"
              placeholder="请选择"
            >
              <el-option
                v-for="item in postList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名:">
            <el-input
              @input="changeName"
              v-model="form.name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="onSubmit">新增员工</el-button>
      </div>
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column prop="id" label="工号" width="86"> </el-table-column>
        <el-table-column prop="name" label="姓名" width="82"> </el-table-column>
        <el-table-column prop="sex" label="性别" width="60"> </el-table-column>
        <el-table-column prop="mobile" label="手机号" width="120">
        </el-table-column>
        <el-table-column prop="identity" label="身份证号" width="180">
        </el-table-column>
        <el-table-column prop="position_time" label="入职时间" width="110">
        </el-table-column>
        <el-table-column prop="post" label="职位" width="80"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="220">
          <template slot-scope="scope">
            <div class="btn_ul flexs">
              <el-button
                class="look"
                @click="handleLookStadd(scope.row)"
                type="text"
                >查看</el-button
              >
              <el-button type="text" v-if="scope.row.user_status == 1" @click="editStaff(scope.row)">修改</el-button>
              <el-button
                @click="handleDimission(scope.row)"
                type="text"
                class="ash"
                :class="{
                  red: scope.row.user_status == 1,
                }"
                >{{ scope.row.user_status == 1 ? "离职" : "已离职" }}</el-button
              >
              <el-button v-if="scope.row.user_status == 1 && (scope.row.position == 2 || scope.row.position == 3 || scope.row.position == 4)" type="text" class="gary" @click="$refs.staff.open(scope.row.id)">调店</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="paging" v-if="tableData.length != 0">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 调店弹框 S -->
    <staff-pop ref="staff" @succeed="changeArea"></staff-pop>
    <!--  调店弹框弹框 E -->
  </div>
</template>

<script>
import StaffPop from '../../components/StaffPop'
export default {
  components: { StaffPop},
  data() {
    return {
      form: {
        page: 1,
        position_time: "",
        position: "",
        name: "",
        dian_id: "",
      },
      total: 0, //
      tableData: [],
      areaList: [], //区域列表
      postList: [
        {
          value: "1",
          label: "区域总监",
        },
        {
          value: "2",
          label: "店长",
        },
        {
          value: "3",
          label: "经理",
        },
        {
          value: "4",
          label: "经纪人",
        },
        {
          value: "5",
          label: "人事",
        },
      ], //职位列表
    };
  },
  methods: {
    editStaff (item) {
      this.$router.push({ path: "/personage/AffairsStaffAdd",query:{id:item.id} });
    },
    changePage (e) {
      this.form.page = e
      this.getStaffList()
    },
    //新增员工
    onSubmit() {
      this.$router.push({ path: "/personage/addStaff" });
    },
    //输入名字
    changeName() {
      this.form.page = 1;
      this.getStaffList();
    },
    //选择门店
    changeArea() {
      this.form.page = 1;
      this.getStaffList();
    },
    //选择职位
    changePost() {
      this.form.page = 1;
      this.getStaffList();
    },
    //选择时间
    changeTime(e) {
      this.form.page = 1;
      this.form.position_time = e;
      this.getStaffList();
    },
    //离职
    handleDimission(row) {
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getStaffList();
          this.$axios.liZhi({ id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.form.page = 1
            this.getStaffList()
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //查看员工
    handleLookStadd(row) {
      this.$router.push({
        path: "/personage/staffDetails",
        query: { id: row.id },
      });
    },
    //获取列表
    getStaffList() {
      this.$axios
        .staffList({
          ...this.form,
          position_time: this.form.position_time / 1000,
        })
        .then((res) => {
          if (res.data) {
            res.data.data.forEach((em) => {
              em.post = this.getPosition(em.position);
            });
            this.tableData = res.data.data;
            this.total = res.data.total;
          }
        });
    },
    getPosition(type) {
      let txt = "";
      switch (type) {
        case 1:
          txt = "区域总监";
          break;
        case 2:
          txt = "店长";
          break;
        case 3:
          txt = "经理";
          break;
        case 4:
          txt = "经纪人";
          break;
        case 5:
          txt = "人事";
          break;
        default:
          break;
      }
      return txt;
    },
    //获取门店列表
    getMenDian() {
      this.$axios.menDian().then((res) => {
        this.areaList = res.data;
      });
    },
  },
  created() {
    this.getStaffList();
    this.getMenDian();
  },
};
</script>

<style lang="less" scoped>
.staff_box {
  padding: 0 20px;
}
/deep/ .cell {
  text-align: center;
}
.btn_ul {
  /deep/ .el-button {
    margin-left: 0;
    width: 50px;
    border: none;
    padding: 0;
    font-size: 14px;
  }

  .look {
    color: #ff9600;
  }
  .ash {
    color: #999999;
  }
  .red {
    color: #ff5151;
  }
  .gary {
    color: #666666;
  }
}
.staff_head {
  display: flex;
  justify-content: space-between;
  /deep/ .el-button {
    width: 100px;
    height: 40px;
    background: #3273f6;
    border-radius: 4px;
  }
  /deep/ .el-input__inner {
    width: 180px;
  }
  /deep/ .el-input {
    width: 180px;
  }
}
</style>
